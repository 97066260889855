import { createBrowserRouter, redirect } from "react-router-dom";
import { ErrorBoundary } from "pages/Root/ErrorBoundary";
import { appRoutes } from "shared/constants/appRoutes";
import { routes } from "router/routes";
import { injectAuthGuard } from "router/helper";

injectAuthGuard(routes);

export const router = createBrowserRouter([
  {
    path: appRoutes.root.path,
    loader: async ({ request }) => {
      const position = request.url.indexOf("/", 9);
      const path = request.url.substring(position + 1);
      if (!path) {
        return redirect(appRoutes.error.path);
      }
      return true;
    },
    lazy: () => import("../pages/Root"),
    ErrorBoundary: ErrorBoundary,
    children: [
      // CANDIDATE - ATTEND INTERVIEW FLOW
      routes.candidateWelcome,
      routes.candidateInterview,
      routes.interviewFeedback,
      routes.interviewComplete,
      routes.interviewStatus,
      //INTERVIEW DETAILS PAGE
      routes.interviewDetails,
      // ERROR PAGE 
      routes.error,
      // LOGIN PAGE
      routes.login,
    ],
  },
  {
    path: "*",
    loader: () => redirect(appRoutes.error.path),
  },
]);
