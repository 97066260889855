import { constants } from "shared/constants/constants";
import { localStore } from "shared/stores/localStore";
import { dataStore } from "shared/stores/dataStore";

export interface IAuthGuard {
    roles?: Array<keyof typeof constants.userRoles>,
    pageRefresh?: boolean
}

export const authGuard = ({ roles, pageRefresh = true }: IAuthGuard = {}) => {
    const localAuth = localStore.auth.getValue();
    const singletonAuth = dataStore.auth.getValue();
    const myRole = localAuth?.role || singletonAuth?.role;
    if(!roles) return false;
    if(myRole && roles.includes(myRole)){
        if(pageRefresh && localAuth?.token) return false;
        if(singletonAuth?.token) return false;
    }
    return true;
}