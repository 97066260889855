import { encoder } from 'shared/utils/encoder';
import { ILsBase, lsBase } from './lsBase';

export const lsEncoded: ILsBase = {
    getValue: (key) => {
        const encodedKey = encoder.encode(key);
        const encodedValue = lsBase.getValue<string>(encodedKey);
        if(encodedValue) return encoder.decode<any>(encodedValue);
        return null;
    },
    setValue: (key, value) => {
        const encodedKey = encoder.encode(key);
        const encodedValue = encoder.encode(value);
        lsBase.setValue(encodedKey, encodedValue);
    },
    deleteValue: (key: string) => {
        const encodedKey = encoder.encode(key);
        localStorage.removeItem(encodedKey);
    },
    clearStorage: lsBase.clearStorage
}