import { candidateInterviewRoute } from "./candidateInterview";
import { candidateWelcomeRoute } from "./candidateWelcome";
import { interviewCompleteRoute } from "./interviewComplete";
import { interviewFeedbackRoute } from "./interviewFeedback";
import { interviewStatusRoute } from "./interviewStatus";
import { interviewDetailsRoute } from "./interviewDetails";
import { adminLoginWithOtpRoute } from './adminLoginOtp'
import { errorRoute } from "./error";

export const routes = {
    candidateWelcome: candidateWelcomeRoute,
    candidateInterview: candidateInterviewRoute,
    interviewFeedback: interviewFeedbackRoute,
    interviewComplete: interviewCompleteRoute,
    interviewStatus: interviewStatusRoute,
    interviewDetails: interviewDetailsRoute,
    login: adminLoginWithOtpRoute,
    error: errorRoute,
}