import { appRoutes } from "shared/constants/appRoutes";
import { LoaderFunctionArgs } from "react-router-dom";

export interface IInterviewDetailsParams {
  params: { interviewId: string };
}

export const interviewDetailsRoute = {
  path: appRoutes.interviewDetails.path,
  lazy: () => import("pages/InterviewDetails"),
  async loader({ params, request }: LoaderFunctionArgs) {
    const { loader } = await import("pages/InterviewDetails/loader");
    return loader({ params, request } as LoaderFunctionArgs);
  },
};
