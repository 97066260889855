import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { pageNotFound, interviewStatus } from "assets/images";
import { trackGoogleAnalyticsActionEvent } from "shared/utils/googleAnalytics";
import { HttpStatus } from "shared/types/enums/HttpStatus";
import { GoogleEvents } from "shared/types/enums/GoogleEvents";
import { texts } from "shared/constants/texts";
import { Message } from "shared/components/Message";

export const ErrorBoundary = () => {
  const { INTERRUPTED_RELOADING_DESCRIPTION,
    ALREADY_ATTENDED_DESCRIPTION,
    LINK_EXPIRE, LINK_EXPIRE_MESSAGE,
    ERROR_GENERIC, ERROR_GENERIC_MESSAGE } = texts

  let error: any = useRouteError();
  let title = ERROR_GENERIC;
  let message = ERROR_GENERIC_MESSAGE;
  let img = pageNotFound;

  if (isRouteErrorResponse(error) && error.status === HttpStatus.UNAUTHORIZED) {
    trackGoogleAnalyticsActionEvent(GoogleEvents.interruptions, { reason: "Link Expired", page: "Interview" });
    title = LINK_EXPIRE;
    message = LINK_EXPIRE_MESSAGE;
    img = interviewStatus;
  }

  if (error?.data?.data?.status === HttpStatus.BAD_REQUEST) {
    message = (error?.data?.data?.message === "Interview over") ? INTERRUPTED_RELOADING_DESCRIPTION : ALREADY_ATTENDED_DESCRIPTION;
    title = error?.data?.data?.message;
    img = interviewStatus;
  };

  return (
    <Message img={img} title={title} message={message} />
  );
}