import { useRouteError } from "react-router-dom";
import { warningGrey } from "assets/icons";
import { texts } from "shared/constants/texts";
import { Message } from "shared/components/Message";

export const ErrorBoundary = () => {
  let error = useRouteError();
  console.error("thrown error", error);
  const { ERROR_GENERIC, ERROR_GENERIC_MESSAGE } = texts;

  return (
    <Message img={warningGrey} title={ERROR_GENERIC} message={ERROR_GENERIC_MESSAGE} />
  );
}