import React from 'react';
import { Logo } from 'shared/components/Logo';
import ss from './styles.module.css';

interface MessageProps {
    img: string;
    title: string;
    message: string;
}

export const Message: React.FC<MessageProps> = ({ img, title, message }) => {
    return (
        <div className={ss.container}>
            <Logo />
            <div className={ss["content-box"]}>
                <img src={img} alt="ERROR" />
                <h3>{title}</h3>
                <label>{message}</label>
            </div>
        </div>
    );
};