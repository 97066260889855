import { screenerLogo } from "assets/icons";
import { joinClass } from "shared/utils/helper";
import { texts } from "shared/constants/texts";
import ss from "./styles.module.css";

interface IPropLogo {
  width?: string;
  containerClassName?: string;
  className?: string;
  mainLogo?: boolean;
};

export const Logo = ({ className, width = "116", mainLogo = true, containerClassName = "" }: IPropLogo) => {
  const logoContent = () => {
    if (mainLogo) {
      return (
        <img
          className={className}
          src={screenerLogo}
          alt="Screener Logo"
          style={{ width: `${width}px` }}
        />
      );
    }
    return (
      <div className={ss["logo-initial"]}>
        <span>{texts.SHORT_SCREENER}</span>
      </div>
    );
  };

  return (
    <div className={joinClass(ss.container, containerClassName)}>
      {logoContent()}
    </div>
  );
};
