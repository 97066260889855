import { redirect } from "react-router-dom";
import { IAppRoutesConfigValue, appRoutes } from "shared/constants/appRoutes";
import { authGuard } from "shared/utils/authGuard";


export const injectAuthGuard = (parentRoute: any) => {
    Object.values(parentRoute).forEach((v: any) => {
        const config = Object.values(appRoutes).find(x => x.path === v?.path) as IAppRoutesConfigValue;
        if (config?.authConfig) {
            const existingLoader = v?.loader ?? (() => true);
            v.loader = async (...args: any) => {
                const { redirectUrl, ...authParams } = config.authConfig || {};
                const isRedirect = authGuard(authParams);
                if (isRedirect) {
                    if (typeof redirectUrl === 'function') return redirect(redirectUrl());
                    return redirect(redirectUrl || appRoutes.root.path);
                }
                return existingLoader(...args);
            }
        }
        if (v?.children) {
            v?.children?.forEach((x: any) => injectAuthGuard({ route: x }))
        }
    })
}