import { appRoutes } from "shared/constants/appRoutes";
import { ErrorBoundary } from "pages/CandidateInterview/ErrorBoundary";

export const candidateInterviewRoute = {
    path: appRoutes.candidateInterview.path,
    async loader() {
      let { loader } = await import("pages/CandidateInterview/loader");
      return loader();
    },
    lazy: () => import('pages/CandidateInterview'),
    ErrorBoundary: ErrorBoundary,
}